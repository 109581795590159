import React, { useEffect, useState } from 'react';
import './App.scss';
import { AnimatePresence, motion } from "framer-motion";
import "./App.scss";
import Loader from './Pages/Loader';
import Home from './Pages/Home';
import Header from './Pages/Header';
import { ParallaxProvider } from 'react-scroll-parallax';
import Image2 from './Images/2.jpg';
import Section from './Pages/Section';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);

  return (
    <layoutId type='crossfade'>
      <ParallaxProvider>
      <AnimatePresence>
        {loading ? (
          <motion.div key='loader'>
            <Loader setLoading={setLoading} />
          </motion.div>
        ) : (
          <>
                <Header />
                <Home />
  
            {!loading && (
              <div className='transition-image final'>
                <motion.img
                  transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                  src={Image2}
                  layoutId='main-image-1'
                />
              </div>
            )}
            <Section />
          </>
        )}
      </AnimatePresence>
      </ParallaxProvider>
    </layoutId>

     )
}

export default App;
