import React, {useEffect} from 'react';
import { cubicBezier, motion, stagger } from "framer-motion";
import Img1 from '../Images/1.jpg';
import Img2 from '../Images/2.jpg';
import Img3 from '../Images/3.jpg';
import Img4 from '../Images/4.jpg';
import Img5 from '../Images/5.jpg';






const Loader = ({setLoading}) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 4000);
        return () => clearTimeout(timer);
    });


    return(
        <div className='loader'>
            <motion.div  className='loader-inner'>
                <motion.div className="image-block image-1"
                    initial={{
                        opacity: 0, y: 200,}} 
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
                            duration: 1.6, delay: 0.35
                        },
                    }}
                    exit={{
                        opacity: 0,
                        y: -200,
                        transition: {
                            ease: "easeInOut",
                            duration: 0.5,
                        },
                    }} >
                    <img src={Img1} alt="" />
                </motion.div>

                <motion.div className="try" 
                    initial={{ opacity: 0, y: 200 }}
                    animate={{
                        opacity: 1,
                        y: 100,
                        transition: {
                            ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
                            duration: 1.6, delay: 0.6
                        },
                    }}
                >
                    <motion.img layoutId="main-image-1" src={Img2} alt="" />
                </motion.div>

                <motion.div className="image-block image-3" initial={{ opacity: 0, y: 200 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
                            duration: 1.6, delay: 0.70
                        },
                    }}
                    exit={{
                        opacity: 0,
                        y: -200,
                        transition: {
                            ease: "easeInOut",
                            duration: 0.8,
                        },
                    }}>
                    <img src={Img3} alt="" />
                </motion.div>

                <motion.div className="image-block image-4" initial={{ opacity: 0, y: 200 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
                            duration: 1.6, delay: 1.5
                        },
                    }}
                    exit={{
                        opacity: 0,
                        y: -200,
                        transition: {
                            ease: "easeInOut",
                            duration: 0.8,
                        },
                    }}>
                    <img src={Img4} alt="" />
                </motion.div>

                <motion.div className="image-block image-5" initial={{ opacity: 0, y: 200 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            ease: cubicBezier(0.6, 0.01, -0.05, 0.95),
                            duration: 1.6, delay: 1.85
                        },
                    }}
                    exit={{
                        opacity: 0,
                        y: -200,
                        transition: {
                            ease: "easeInOut",
                            duration: 0.8,
                        },
                    }}>
                    <img src={Img5} alt="" />
                </motion.div>
            </motion.div>
        </div>
    )
};

export default Loader


