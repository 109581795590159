import React from "react";
import { motion, useScroll } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';


const Header = () => {
    const { scrollYProgress } = useScroll();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    return (
        <>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
        <motion.div
            initial={{ opacity: 0, y: -180 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.6,
            }}
            className='header'>
            <div className='header-inner'>
                <div className='contact'>
                        <MenuIcon fontSize="inherit" onClick={toggleDrawer(true)} />
                        <Drawer  anchor='right' open={open} onClose={toggleDrawer(false)}>
                            <div onClick={toggleDrawer(false)} className="menu-title"><a className="nav" href="#about">About</a></div>
                            <div onClick={toggleDrawer(false)} className="menu-title"><a className="nav" href="#product">Product</a></div>
                            <div onClick={toggleDrawer(false)} className="menu-title"><a className="nav" href="#questions">FAQ</a></div>
                            <div onClick={toggleDrawer(false)} className="menu-title"><a className="nav" href="#shop">Shop</a></div>
                            <div onClick={toggleDrawer(false)} className="menu-title"><a className="nav" href="#contact">Contact</a></div>
                        
                        </Drawer>
                    
                </div>
            </div>
        </motion.div>
        </>
    );
};

export default Header;